import React from "react";
import Items from "./Items";
import "./Portfolio.css";
import SmartCV from "../src/images/SA_logo.png";
import Mercato from "../src/images/mercato.jpeg";
import Forest from "../src/images/forest.png";
import Redware from "../src/images/redware.jpg";

function Portfolio() {
  return (
    <div id="portfolio" className="portfolio">
      <div className="portfolio__wrapper">
        <div className="portfolio__top">
          <h2>portfolio</h2>
          <p>My Work</p>
        </div>
        <div className="portfolio__content">
          <div className="work__item">
            <div className="quick__intro">
              <p>
                Due to confidentiality agreements, I'm unable to display the
                majority of my work from previous engagements. However, please
                take my word for the significant contributions I've made to
                these organisations.
              </p>
            </div>
            <Items
              logo={Mercato}
              title="Mercato Solutions"
              appName="Knowledge Cube"
              tags={[
                "User Experiance Design",
                "UX Strategy",
                "Interface Design",
              ]}
              text={
                <p>
                  As a UX Designer deeply involved in the development of
                  KnowledgeKube, my role centered on crafting an intuitive and
                  efficient platform that simplifies the app creation process
                  for users without coding experience. This work leveraged my
                  expertise in creating user-centric designs that enhance
                  accessibility and functionality, ensuring a seamless
                  experience for both technical and non-technical users. My
                  contributions were aimed at streamlining app development, from
                  concept to deployment, across various platforms, embodying the
                  principles of rapid, scalable, and flexible design.
                </p>
              }
            />
            <Items
              logo={SmartCV}
              title="Smart Apprentices"
              appName="Digital CV"
              tags={[
                "User Experiance Design",
                "UX Strategy",
                "Interface Design",
                "Usability Testing",
              ]}
              text={
                <p>
                  As the lead UX Designer at Smart Apprentices, I contributed to
                  the development of My Digital CV, a platform enhancing
                  apprentices' marketability through a comprehensive digital
                  profile. This initiative is part of an advanced suite aimed at
                  streamlining apprenticeship and training processes, including
                  features for tracking, assessment, and feedback, such as Smart
                  Assessor and Smart EPA.
                </p>
              }
            />
            <Items
              logo={SmartCV}
              title="Smart Apprentices"
              appName="SEPA"
              tags={[
                "User Experiance Design",
                "UX Strategy",
                "Interface Design",
              ]}
              text={
                <p>
                  As the lead UX Designer for Smart SEPA, I designed a
                  user-centric platform for end point assessments, focusing on
                  streamlining the EPA process for apprentices, assessors, and
                  educational organizations. Key features include an intuitive
                  interface, customisable management systems, automated
                  communications to reduce administrative work, and integrity
                  checks to prevent conflicts of interest.
                </p>
              }
            />

            <Items
              logo={SmartCV}
              title="Smart Apprentices"
              appName="Smart VLE"
              tags={[
                "User Experiance Design",
                "UX Strategy",
                "Interface Design",
                "Usability Testing",
              ]}
              text={
                <p>
                  Smart Apprentices, now a part of Advanced, I contributed to an
                  innovative virtual learning environment that is integral to
                  Smart Apprentices' suite of technologies aimed at streamlining
                  apprenticeship delivery. Smart VLE facilitates the creation,
                  delivery, and automatic marking of knowledge tests, supporting
                  a wide range of educational programs including
                  apprenticeships, NVQs, and traineeships.
                </p>
              }
            />

            <Items
              logo={Forest}
              title="Forest Holidays"
              appName="Forest Holidays"
              tags={[
                "User Experiance Design",
                "UX Strategy",
                "Interface Design",
                "Usability Interviews",
                "Usability Testing",
                "A/B Testing",
              ]}
              text={
                <p>
                  I was tasked with analysing and enhancing the mobile version
                  of our website to address underperformance in holiday
                  bookings. My approach focused on identifying usability
                  barriers and optimizing the user journey for mobile users,
                  with an emphasis on simplifying the booking process. Through
                  data-driven design decisions, A/B testing, and user feedback,
                  I implemented intuitive interfaces and streamlined
                  interactions to encourage more bookings. This project required
                  a balance of creative and analytical skills to improve user
                  engagement and conversion rates on mobile platforms.
                </p>
              }
            />

            <Items
              logo={Redware}
              title="Redware Ltd"
              appName="Redware Ltd"
              tags={[
                "User Experiance Design",
                "UX Strategy",
                "Interface Design",
                "Usability Interviews",
                "Usability Testing",
              ]}
              text={
                <p>
                  In my role as a UX Product Designer at Redware for the Volvo
                  Global LMS project, I focused on enhancing the platform's user
                  experience, including creating custom screens tailored to
                  Volvo's specific requirements. This involved designing
                  intuitive interfaces that cater to a diverse, global user
                  base, improving accessibility, and ensuring seamless
                  navigation. My efforts were directed towards streamlining the
                  learning process, enhancing user engagement, and meeting the
                  unique needs of Volvo's global workforce through strategic UX
                  design and development.
                </p>
              }
            />

            {/* <div className="item">
              <h3>Johnson Johnson</h3>
              <h4>TCS Connected Clinic Trials</h4>
              <span>User Interface Design</span>
              <p>
                Designed a seamless integrated system that digitised the core
                processes of a clinic trial. It is 'One of its kind' of products
                products in the Clinical Triavis domain in the world.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
