import React from 'react';
import './Client1.css';
import { Link } from 'react-router-dom';
import Research from '../../src/images/byu/research-goals.jpg';
import Banner from '../../src/images/byu/banner.jpg';
import ProfilePic from '../../src/images/me.jpg';
import Persona1 from '../../src/images/byu/persona1.jpg';
import Persona2 from '../../src/images/byu/persona2.jpg';
import Painpoints from '../../src/images/byu/old-design-painpoints.png';
import Whiteboard from '../../src/images/byu/whiteboarding.jpg';
import Wireannototed from '../../src/images/byu/wireframes-annototed.png';
import Wireframe1 from '../../src/images/byu/wireframe1.jpg';
import IAv1 from '../../src/images/byu/ia-v1.png';
import Cardsorting from '../../src/images/byu/card-sorting.jpg';
import IAann from '../../src/images/byu/ia-annatoted.png';
import NewDesign from '../../src/images/byu/new-design.png';

function Client1() {
    return (
        <>        
            <div id="about" className="Byuidaho">      

                <div className="navbar">
                    <div className="container">
                        <Link to="/">
                            <img src={ProfilePic} alt=""/>
                            Wayne Hewitt
                        </Link>
                    </div>
                </div>

                <div className="page__banner"  style={{ backgroundImage: `url(${Banner})`}}>
                    <h1 className="banner__title">Redesign of the Client Portal</h1>
                    <h3 className="banner__title">UX Research, UI/UX Design, Usability Testing, Wireframing</h3>
                </div>

                <div className="container--sm">
                    <h3>The Product</h3>
                    <p>The home page for the students of Client. The student portal aims to provide students with the most up-to-date information so that they can access the university website and find the tools they need.</p>
                    <span className="spacer"></span>
                    <h3>The problem</h3>
                    <p>The Client portal lacks relevant information and resources for students. As a result, students have difficulty navigating throughout the site, accessing services, completing necessary tasks.</p>
                </div>
                <span className="spacer"></span>
                <div className="container">
                    <div>
                        <h3>Research Process</h3>
                        <p>
                            <b>Defining Assumptions and Research Goals</b> <br /> <br />
                            From the point of view of a developer, the redesign vision and its outcome may look completely different from that of a student who would use the portal on a regular basis. That meant a common ground had to be discovered during the process of UX research to merge what we, as developers, could identify as weak points and what the student would see as the main pain points to address. With that in mind, research goals were identified as per below.  </p>
                    </div>

                    <div class="container flex--2">
                        <div>    
                            <p><b>Personal Assumptions:</b></p>
                            <ul>
                                <li>Students primarily use email and iLearn.</li>
                                <li>The data below the menu is unused.</li>
                                <li>The "More" dropdown tab conceals vital options for students.</li>
                            </ul>
                        </div>
                        <div>
                            
                            <p><b>Research Goals:</b></p>
                            <ul>
                                <li>Find out what students want and need.</li>
                                <li>Discover what students are and are not using on the portal.</li>
                                <li>Define critical tasks.</li>
                            </ul>
                        </div>
                    </div>                    
                    <span className="spacer"></span>
                    <div class="container">
                        <img src={Research} alt="Research for diffrent types of mobile apps" />
                    </div>
                   
                </div>

                
                <span className="spacer"></span>
                <div className="container--sm">
                    <div>
                        <h3>Analytics team</h3>
                        <p>To put some of our professional, yet subjective assumptions to the test, I needed some solid data on the current use of the portal, so a meeting took place with the analytics team in an attempt to collect data on the most ordinary use patterns of the portal page.</p>

                        <p>Unfortunately, it was quickly discovered that there had not been any analytics of the portal use in place, so some fieldwork had to be arranged for collection of primary data from the target audience - ordinary users of the portal.</p>                    
                    </div>
                </div>

                
                <span className="spacer"></span>
                <div className="container flex--2">
                    <div>
                        <h3>User Interviews</h3>
                        <p>Twenty-two usability interviews were conducted by myself and a UX researcher, with the primary goal of trying to establish the most common use patterns.</p>  

                        <p>A range of open-ended questions was discussed in the process of the interviews conducted, to better grasp students' personal experience of using the portal, the most important tasks they had to perform, what the most important information was for the user to have immediate access to, the needs not currently met, etc.</p>                
                    </div>
                    <div>                    
                        
                        <p><b>Here are some key insights we identified:</b></p> 
                        <ul>
                            <li>The most frequently used tabs are iLearn and Email.</li>
                            <li>Students ignore the links beneath the tabs.</li>
                            <li>Inadequate actionable alerts</li>
                            <li>The menu labelling does not match the students' mental image.</li>
                        </ul>
                    </div>
                </div>                
                <span className="spacer"></span>
                <div class="container">                    
                    <h3>User quotes from interviews</h3>
                </div>
                <div class="container flex--2">
                    <div class="quote"><p>“I feel like this portal is more of a maze than a doorway to get to where I need.”</p></div>
                    <div class="quote"><p>“This page is not clearly laid out so I just bookmark the important links I need in my browser.”</p></div>
                </div>

                
                <span className="spacer"></span>
                <div className="container--sm">
                    <div>
                        <h3>Define</h3>
                        <p>The main aspect to define in the course of this research segment was the concepts of success and impact. In discussion with other stakeholders, it was agreed that success and impact for the purpose of this project could be encapsulated in the following statements about the impact of the innovations we were trying to implement during the redesign process. </p>       

                        <p>Defining success for student as users of the portal</p>      

                        <ul>
                            <li>Students' time is saved.</li>
                            <li>Awareness is raised in students of their educational progress.</li>
                            <li>Students are given valuable/useful information.</li>
                        </ul>
                    </div>
                </div>

                <span className="spacer"></span>
                <div className="container"> 
                    <div> 
                        <h3>Persona Development</h3>
                        <p>In the process of the team consultation, it was agreed to develop and focus on two personas - a post-graduate student who is looking to save time and manage deadlines and a freshman looking to navigate a range of resources available on campus.</p>


                        <div class="container flex--3">
                            <div></div>
                            <div>                                
                                <img src={Persona1} alt="Persona one" width="auto"/>
                            </div>
                            <div>                                
                                <img src={Persona2} alt="Persona two" width="auto" />
                            </div>
                            <div></div>
                        </div>
                                      
                    </div>
                </div>

                <span className="spacer"></span>
                <div className="container">
                    <div>
                        <h3>Pain Points</h3>
                        <p>During the user interviews, a few direct points were pointed out straight away that caused a lot of frustration to ordinary users. Each pain point is highlighted in red below:</p>     

                        
                        <img src={Painpoints} alt="Persona two" width="auto" />

                    </div>
                </div>

                <span className="spacer"></span>
                <div className="container">
                    <div>
                        <h3>Concept and Design</h3>
                        <p>The next step in the process of the full UX-powered re-design was a sum-up exercise in an attempt to incorporate the research findings into the user interface with the user profiles, objectives, success/impact goals etc. in mind.</p>     

                        
                        <img src={Whiteboard} alt="Persona two" width="auto" />

                    </div>
                </div>

                {/** Interface */}


                <span className="spacer"></span>
                <div className="container">
                    <div>
                        <h3>Wireframes</h3>
                        <p>Following the initial ideas mining exercise, a higher-fidelity version of the wireframes was developed, rich in detail, that was demonstrated to some ordinary users in the process of additional fieldwork, to get further feedback on the new design and layout and discover any potential pitfalls.</p>    
                    </div>
                </div>

                <div class="flex--2">
                    <div>
                        <img src={Wireannototed} alt="Persona two" width="auto" /></div>
                    <div>
                        <img src={Wireframe1} alt="Persona two" width="auto" /></div>
                </div>


                <span className="spacer"></span>
                <div className="container">
                    <div>
                        <h3>Reconstructing the IA</h3>
                        <p>A couple of potential pain points and straight-up errors were quickly discovered, namely an excessive number of tabs used, with some labelled incorrectly. This step provided invaluable insight and enabled us to devise a clearer structure of the layout and a far better understanding of the components involved.</p>    

                        
                        <img src={IAv1} alt="Persona two" width="auto" />
                    </div>
                </div>

                
                
                <span className="spacer"></span>
                <div className="container">
                    <div>
                        <h3>Open Card Sort</h3>
                        <p>Given the level of content complexity and its multifaceted structure, a group of users were invited for an open card sort exercise, wherein the process of observation and direct questioning, we were trying to establish their mental models and what the portal should be able to do for them and why.</p>    


                        
                        <img src={Cardsorting} alt="Persona two" width="auto" />
                        
                    </div>
                </div>

                <span className="spacer"></span>
                <div className="container">
                    <div>
                        <h3>Closed Card Sort</h3>
                        <p>The open card exercise aids in the generation of menu tab ideas. Academics, Finances, and Resources were the three most frequently used categories, which effectively were added for the next stage of the research exercise - a closed card sort. Four different closed cards sorts were carried out, with close observation and questioning in an attempt to finalise the structure of the menu and its functionality.</p>    

                                            
                        
                        <img src={IAann} alt="Persona two" width="auto" />
                    </div>
                </div>

                
                <span className="spacer"></span>
                <div className="container">
                    <div>
                        <h3>Site Structure - New and Improved</h3>                      
                        
                        <img src={IAann} alt="Persona two" width="auto" />
                    </div>
                </div>

                <span className="spacer"></span>
                <div className="container">
                    <div>
                  
                        
                        <img src={NewDesign} alt="Persona two" width="auto" />
                    </div>
                </div>


            </div>
            
        </>
    )
}

export default Client1

