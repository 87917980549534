import React from "react";
import "./About.css";
import CV from "../src/cv/waynehewitt.pdf";
import axure from "../src/images/axure.png";
import adobexd from "../src/images/adobexd.png";
import balsamiq from "../src/images/balsamiq.png";
import figma from "../src/images/figma.png";
import photoshop from "../src/images/photoshop.png";
//import zeplin from "../src/images/zeplin.png";

function About() {
  function bday() {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    let bdayDate = 6;
    let bdayMonth = 2;
    let bdayYear = 1980;

    if (date === bdayDate && month === bdayMonth) {
      // if dtae and month = to todayes date
      var age = year - bdayYear;
      return age + " - Today's my birthday";
    } else {
      // if today date > birthday
      if (month > bdayMonth || date > bdayDate) {
        return year - bdayYear;
      } else {
        return year - bdayYear - 1;
      }
    }
  }

  return (
    <div id="about" className="about">
      <div className="about__wrapper">
        <div className="about__top">
          <h2>About Me</h2>
          <p>Know Me More</p>
        </div>
        <div className="about__content">
          <div className="about__contentLeft">
            <h2>
              I'm <span>Wayne Hewitt</span>, UX/UI Designer
            </h2>
            <p>
              I'm Wayne Hewitt, a UX/UI Designer with a strong drive for solving
              complex challenges in the online business sphere. I bring to the
              table a wealth of experience in devising logical, innovative
              solutions and a passion for web design, mobile app development,
              and enhancing user experiences. My commitment to personal
              development ensures I remain at the cutting edge of the industry,
              making me a valuable asset to any development team. I'm eager to
              contribute to your company's success and discuss how my skills
              align with your needs in person.{" "}
            </p>
          </div>
          <div className="about__contentRight">
            <p>
              <strong>Name:</strong> Wayne Hewitt
            </p>
            {/* <p><strong>Email:</strong> <a href="mailto:hello@waynehewitt.co.uk">hello@waynehewitt.co.uk</a></p> */}
            <p>
              <strong>Age:</strong> {bday()}
            </p>
            <p>
              <strong>From:</strong> Redditch
            </p>
            <div className="spacer"></div>
            <a href={CV} className="buttonCV" download>
              Download CV
            </a>
          </div>
        </div>
        <div className="about__bottom"></div>
        <div class="spacer"></div>
        <div class="software">
          <div>
            <img src={axure} alt="" title="" />
            <p>Axure</p>
          </div>
          <div>
            <img src={adobexd} alt="" title="" />
            <p>Adobe XD</p>
          </div>
          <div>
            <img src={balsamiq} alt="" title="" />
            <p>Balsamiq</p>
          </div>
          <div>
            <img src={figma} alt="" title="" />
            <p>Figma</p>
          </div>
          <div>
            <img src={photoshop} alt="" title="" />
            <p>Photoshop</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
