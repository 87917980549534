import React from "react";
import "./HeroBanner.css";
import { Parallax } from "react-parallax";
import Typical from "react-typical";

function HeroBanner() {
  const image1 =
    "https://compassionate-leakey-e9b16b.netlify.app/images/header-background.jpg";
  return (
    <div id="home" className="hero">
      <Parallax bgImage={image1} strength={200}>
        <div style={{ height: 947 }}>
          <div className="hero__innerText">
            <p>Welcome</p>

            <Typical
              loop={Infinity}
              wrapper="h1"
              steps={[
                "I'm Wayne Hewitt.",
                6000,
                "I'm a User Experience Designer.",
                5000,
                "I'm a User Interface Designer.",
                5000,
              ]}
            />

            <p>based in Redditch, Worcestershire.</p>
          </div>
        </div>
      </Parallax>
    </div>
  );
}

export default HeroBanner;
