import React from 'react';
import './StoreLocator.css';
import { Link } from 'react-router-dom';
import Mockup from '../../src/images/storefinder/mockup.png';
import Research from '../../src/images/storefinder/research.jpg';
import Brainstorming from '../../src/images/storefinder/brainstorming.jpg';
import Wireframe from '../../src/images/storefinder/wireframe.jpg';
import SearchOptions from '../../src/images/storefinder/searchoptions.png';
import StoreBanner from '../../src/images/storefinder/storelocator-banner.jpg';
import ProfilePic from '../../src/images/me.jpg';

function StoreLocator() {
    return (
        <>        
            <div id="about" className="storeLocator">      

                <div className="navbar">
                    <div className="container">
                        <Link to="/">
                            <img src={ProfilePic} alt=""/>
                            Wayne Hewitt
                        </Link>
                    </div>
                </div>

                <div className="page__banner"  style={{ backgroundImage: `url(${StoreBanner})`}}>
                    <h1 className="banner__title">Store Finder</h1>
                    <h3 className="banner__title">UX Research, UI/UX Design, Usability Testing</h3>
                </div>

                <div className="container--sm">
                    <h3>The Product</h3>
                    <p>The mobile app is designed to enable users ( traders) to locate vacant premises in participating stores, apply for a lease, and manage their accounts by making payments, updating account information, etc. </p>
                    <span className="spacer"></span>
                    <h3>The problem</h3>
                    <p>Results view needs to be limited to a maximum of 10 items.
                    Currently, users are unable to search by the name of the store.
                    A user's location cannot be taken into account when a search is performed, due to a consent order in place.</p>
                    <p>The screen is cluttered, as pins are too close together, so it is hard to select a particular property.</p>
                    <span className="spacer"></span>
                    <h3>The Project</h3>
                    <p>Development of a more efficient store location feature to enable users to manage their accounts more effectively, with a focus on the application process.</p>
                </div>
                <span className="spacer"></span>
                <div className="container flex--2">
                    <div>
                        <h3>Research Process</h3>
                        <p>Having scrutinized a range of alternative apps across various industries, in order to analyze their approach to location services and user's interaction with the visual mapping of search results, the following points  have been identified:</p>

                        <ul>
                            <li>Preference for a list view;</li>
                            <li>Map view is optional;</li>
                            <li>Dynamic search is prevalent;</li>
                            <li>Indication of selection parameters before each step.</li>
                        </ul>
                    </div>
                    <div>                    
                        <img src={Research} alt="Research for diffrent types of mobile apps"/>    
                    </div>
                </div>

                
                <span className="spacer"></span>
                <div className="container flex--2">
                    <div>                    
                        <img src={Brainstorming} alt="Research for diffrent types of mobile apps"/>    
                    </div>
                    <div>
                        <h3>Going Forward</h3>
                        <p>Having researched the competition and the existent products, it was time for a thorough consultation with the legal and compliance team. As the use of geolocation was restricted, the project presented a unique challenge and put the company at quite a disadvantage, provided every single competitor deployed some form of geolocation to enhance their services.  Having reviewed the project, it became apparent that the use of geolocation was indeed possible, provided every single client was asked for their consent on every occasion of their geolocation being requested. Although that meant additional steps and less efficiency in terms of time spent, it was still a far better solution than having to rely on the limited functionality currently in place.</p>
                    
                    </div>
                </div>

                
                <span className="spacer"></span>
                <div className="container flex--2">
                    <div>
                        <h3>Initial Sketches</h3>
                        <p>Having built a range of simple wireframes with basic shapes to capture the general concept of the design and functionality, I had to scrutinize the design from the developer's point of view and it quickly became apparent that it would be rather difficult to integrate a smart search bar into the app that would distinguish between a store address and a store name automatically.  This led to some adjustments in the initial design and its wireframing.</p>                  
                    </div>
                    <div>                    
                        <img src={Wireframe} alt="Research for diffrent types of mobile apps"/>  
                    </div>
                </div>

                
                <span className="spacer"></span>
                <div className="container--sm">
                    <div>
                        <h3>User flow</h3>
                        <p>The user flow below demonstrates various ways to interact with the search function to meet the user's needs.</p>                  
                    </div>
                </div>

                <span className="spacer"></span>
                <div className="">
                    <div>                   
                        <img src={SearchOptions} alt="Store finder mock up on mobile devices"/>                
                    </div>
                </div>

                <span className="spacer"></span>
                <div className="container--sm">
                    <div>
                        <h3>Mock up</h3>
                        <p>Using Balsamiq, it was time to work on a range of mocks to explore UI elements and run a basic user test to ensure the general concept for the product meets the needs of the client, before diving deeper into the UI design and functionality.</p>                  
                    </div>
                </div>

                <span className="spacer"></span>
                <div className="container-full">
                    <div>                   
                        <img src={Mockup} alt="Store finder mock up on mobile devices"/>                
                    </div>
                </div>

                {/** Interface */}


                <span className="spacer"></span>
                <div className="container--sm">
                    <div>
                        <h3>Usability Testing</h3>
                        <p>A small sample of 10 regular users of similar applications was recruited to test the prototype.</p>     
                        <span className="spacer"></span>
                        <h3>Test Objectives:</h3>             

                        <ul>
                            <li>How straightforward setting up the application is for all users;</li>
                            <li>How easy it is to use the application to find a store;</li>
                            <li>How easy it is to use the application to apply to a store;</li>
                            <li>How user-friendly the overall experience is;</li>
                            <li>Any recommendations for improvement.</li>
                        </ul>
                    </div>
                </div>

                

                <span className="spacer"></span>
                <div className="container--sm">
                    <div>
                        <h3>Test Results</h3>
                        <p>Test Completion Rate: 100%</p>     

                        <p>Error-free rate: 95%</p>          

                        <ul>
                            <li>100% of the participants were able to set up the device successfully;</li>
                            <li>100% of the participants managed to successfully search for vacant premises in participating stores;</li>
                            <li>100% of the participants were able to apply to the store of their choice successfully;</li>
                            <li>80 % of the participants are willing to use these features on a regular basis;</li>
                            <li>80 % of the participants felt the experience was smooth and easy.</li>
                        </ul>
                    </div>
                </div>


                
                <span className="spacer"></span>
                <div className="container-full flex--4 align--center">
                    <div>
                        <h3 className="large">100%</h3>
                        <p className="large">Test completion rate</p>     
                    </div>
                    <div>
                        <h3 className="large">95%</h3>
                        <p className="large">95% Error-free rate</p>     
                    </div>
                    <div>
                        <h3 className="large">80%</h3>
                        <p className="large">Smooth and easy experience</p>     
                    </div>
                    <div>
                        <h3 className="large">80%</h3>
                        <p className="large">Are willing to use the app</p>     
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default StoreLocator

