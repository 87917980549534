import React from 'react';
import Header from '../Header';
import Content from '../Content';
import Scroll from '../scroll';


function Home() {
    return (
        <>
            <Scroll showBelow={100} />
            <Header />
            <Content />
        </>
    )
}

export default Home
