import React, { useState } from "react";
import "./Header.css";
//import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedinIcon from "@material-ui/icons/LinkedIn";
import { Link } from "react-scroll";
import MenuIcon from "@material-ui/icons/Menu";
import ProfilePic from "../src/images/me.jpg";

function Header() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  return (
    <>
      <div className="burgerMenu">
        <MenuIcon className="burgerMenu--show" onClick={showSidebar} />
      </div>
      <div className="header">
        <div className={sidebar ? "nav" : "nav nav--active"}>
          <img className="nav__profile" src={ProfilePic} alt="" />

          <h1>Wayne Hewitt</h1>

          <div className="nav__links">
            <Link className="button" to="home" onClick={showSidebar}>
              Home
            </Link>
            <Link className="button" to="about" onClick={showSidebar}>
              About Me
            </Link>
            <Link className="button" to="portfolio" onClick={showSidebar}>
              Portfolio
            </Link>
          </div>

          <div className="nav__social">
            <a
              href="https://www.linkedin.com/in/wayne-hewitt-a3213277/"
              target="_blank"
            >
              <LinkedinIcon />
            </a>
            <a href="https://twitter.com/Levendemg" target="_blank">
              <TwitterIcon />
            </a>
            <a href="https://www.instagram.com/levendemg/" target="_blank">
              <InstagramIcon />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
