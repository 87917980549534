import React from "react";
import "./Items.css";

function Items(props) {
  const { logo, appName, tags, title, text } = props;
  return (
    <>
      <div className="item">
        <img src={`${logo}`} alt={title} />
        <h2>{appName}</h2>
        <div className="item__tags">
          {
            // pull tags through array list
            tags.map((tag) => (
              <span className="tags">
                <i>{tag}</i>
              </span>
            ))
          }
        </div>
        <span className="Main_text">{text}</span>
      </div>
    </>
  );
}

export default Items;
