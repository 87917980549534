import React from "react";
import About from "./About";
import "./Content.css";
import HeroBanner from "./HeroBanner";
import Portfolio from "./Portfolio";
//import Contact from './Contact';

function Content() {
  return (
    <div className="content">
      <HeroBanner />
      <About />
      <Portfolio />
    </div>
  );
}

export default Content;
